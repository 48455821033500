<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-11-11 16:31:41
 * @LastEditTime: 2022-11-11 16:44:35
 * @Description: 完成重置
 * @FilePath: \BusinessPlatform\src\views\login\components\resetPassword\completeReset.vue
 -->

<template>
    <div class="completeReset">
        <h4>重置成功</h4>
        <div>
        </div>
        <el-button
            type="primary"
            class="loginBtn"
            @click="reLogin"
        >
            立 即 登 录
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'complete-reset',
    components: {},
    data() {
        return {};
    },
    watch: {},
    created() {},
    mounted() {},
    computed: {},
    methods: {
        reLogin() {
            this.$emit('backLogin');
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.completeReset
    h4
        font-size 0.32rem
        color #001A2C
        margin-bottom: 0.1rem
    >div
        height 1.86rem
        background: url(../../images/completeReset.png) center no-repeat
    .loginBtn
        width 100%
</style>
